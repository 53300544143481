/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateUpdateReflectionCommand } from '../model/createUpdateReflectionCommand';
import { CreateUpdateReflectionCommandResult } from '../model/createUpdateReflectionCommandResult';
import { DeleteReflectionCommandResult } from '../model/deleteReflectionCommandResult';
import { GetQuestionAnswersPerDayQueryResult } from '../model/getQuestionAnswersPerDayQueryResult';
import { GetReflectionStatisticsQueryResult } from '../model/getReflectionStatisticsQueryResult';
import { GetReflectionsQueryResult } from '../model/getReflectionsQueryResult';
import { GetSingleReflectionQueryResult } from '../model/getSingleReflectionQueryResult';
import { InlineResponse400 } from '../model/inlineResponse400';
import { ResourceScopeType } from '../model/resourceScopeType';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ReflectionService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * [PORTAL] Creates or updates template of relfection
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reflectionCreateUpdatePost(body?: CreateUpdateReflectionCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateUpdateReflectionCommandResult>;
    public reflectionCreateUpdatePost(body?: CreateUpdateReflectionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateUpdateReflectionCommandResult>>;
    public reflectionCreateUpdatePost(body?: CreateUpdateReflectionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateUpdateReflectionCommandResult>>;
    public reflectionCreateUpdatePost(body?: CreateUpdateReflectionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateUpdateReflectionCommandResult>('post',`${this.basePath}/Reflection/CreateUpdate`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets reflection templates list with questions
     * 
     * @param filterLevel 
     * @param filterCreatedByUserUids 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reflectionGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetReflectionsQueryResult>;
    public reflectionGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetReflectionsQueryResult>>;
    public reflectionGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetReflectionsQueryResult>>;
    public reflectionGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterLevel !== undefined && filterLevel !== null) {
            queryParameters = queryParameters.set('Filter.Level', <any>filterLevel);
        }
        if (filterCreatedByUserUids) {
            filterCreatedByUserUids.forEach((element) => {
                queryParameters = queryParameters.append('Filter.CreatedByUserUids', <any>element);
            })
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetReflectionsQueryResult>('get',`${this.basePath}/Reflection`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets reflection templates list with questions
     * 
     * @param teamUid 
     * @param isMyClients 
     * @param questionUid 
     * @param selectedDateLocal 
     * @param groupUid 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reflectionQuestionAnswersGet(teamUid?: string, isMyClients?: boolean, questionUid?: string, selectedDateLocal?: string, groupUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetQuestionAnswersPerDayQueryResult>;
    public reflectionQuestionAnswersGet(teamUid?: string, isMyClients?: boolean, questionUid?: string, selectedDateLocal?: string, groupUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetQuestionAnswersPerDayQueryResult>>;
    public reflectionQuestionAnswersGet(teamUid?: string, isMyClients?: boolean, questionUid?: string, selectedDateLocal?: string, groupUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetQuestionAnswersPerDayQueryResult>>;
    public reflectionQuestionAnswersGet(teamUid?: string, isMyClients?: boolean, questionUid?: string, selectedDateLocal?: string, groupUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (teamUid !== undefined && teamUid !== null) {
            queryParameters = queryParameters.set('TeamUid', <any>teamUid);
        }
        if (isMyClients !== undefined && isMyClients !== null) {
            queryParameters = queryParameters.set('IsMyClients', <any>isMyClients);
        }
        if (questionUid !== undefined && questionUid !== null) {
            queryParameters = queryParameters.set('QuestionUid', <any>questionUid);
        }
        if (selectedDateLocal !== undefined && selectedDateLocal !== null) {
            queryParameters = queryParameters.set('SelectedDateLocal', <any>selectedDateLocal);
        }
        if (groupUid !== undefined && groupUid !== null) {
            queryParameters = queryParameters.set('GroupUid', <any>groupUid);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetQuestionAnswersPerDayQueryResult>('get',`${this.basePath}/Reflection/QuestionAnswers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets reflection templates list with questions
     * 
     * @param teamUid 
     * @param organizationUid 
     * @param isMyClients 
     * @param startDateLocal 
     * @param endDateLocal 
     * @param groupUid 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reflectionStatisticsGet(teamUid?: string, organizationUid?: string, isMyClients?: boolean, startDateLocal?: string, endDateLocal?: string, groupUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetReflectionStatisticsQueryResult>;
    public reflectionStatisticsGet(teamUid?: string, organizationUid?: string, isMyClients?: boolean, startDateLocal?: string, endDateLocal?: string, groupUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetReflectionStatisticsQueryResult>>;
    public reflectionStatisticsGet(teamUid?: string, organizationUid?: string, isMyClients?: boolean, startDateLocal?: string, endDateLocal?: string, groupUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetReflectionStatisticsQueryResult>>;
    public reflectionStatisticsGet(teamUid?: string, organizationUid?: string, isMyClients?: boolean, startDateLocal?: string, endDateLocal?: string, groupUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (teamUid !== undefined && teamUid !== null) {
            queryParameters = queryParameters.set('TeamUid', <any>teamUid);
        }
        if (organizationUid !== undefined && organizationUid !== null) {
            queryParameters = queryParameters.set('OrganizationUid', <any>organizationUid);
        }
        if (isMyClients !== undefined && isMyClients !== null) {
            queryParameters = queryParameters.set('IsMyClients', <any>isMyClients);
        }
        if (startDateLocal !== undefined && startDateLocal !== null) {
            queryParameters = queryParameters.set('StartDateLocal', <any>startDateLocal);
        }
        if (endDateLocal !== undefined && endDateLocal !== null) {
            queryParameters = queryParameters.set('EndDateLocal', <any>endDateLocal);
        }
        if (groupUid !== undefined && groupUid !== null) {
            queryParameters = queryParameters.set('GroupUid', <any>groupUid);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetReflectionStatisticsQueryResult>('get',`${this.basePath}/Reflection/Statistics`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Deletes reflection template
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reflectionUidDelete(uid: string, observe?: 'body', reportProgress?: boolean): Observable<DeleteReflectionCommandResult>;
    public reflectionUidDelete(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteReflectionCommandResult>>;
    public reflectionUidDelete(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteReflectionCommandResult>>;
    public reflectionUidDelete(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling reflectionUidDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeleteReflectionCommandResult>('delete',`${this.basePath}/Reflection/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets single reflection template
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reflectionUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetSingleReflectionQueryResult>;
    public reflectionUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSingleReflectionQueryResult>>;
    public reflectionUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSingleReflectionQueryResult>>;
    public reflectionUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling reflectionUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetSingleReflectionQueryResult>('get',`${this.basePath}/Reflection/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
